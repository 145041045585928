<script setup lang="ts">
const props = defineProps({
  label: {
    type: String,
  },
  price: {
    type: Number,
    required: true,
  },
  withPadding: {
    type: Boolean,
    default: false,
  },
  returnLine: {
    type: Boolean,
    default: true,
  },
})

const formattedPrice = computed(() => {
  if (props.price === undefined) return

  return props.price.toLocaleString('fr-FR').replace(/\u202F/g, ' ')
})
</script>

<template>
  <div
    class="price--container"
    :class="{ with_padding: withPadding, return_line: returnLine }"
  >
    <span
      v-if="label"
    >
      {{ label }}
    </span>
    <span class="price">
      <span class="price-value">{{ formattedPrice }} €</span>
      <span
        v-if="returnLine"
        class="text"
      >
        / pers.
      </span>
    </span>
    <span
      v-if="!returnLine"
      class="text"
    >
      / pers.
    </span>
  </div>
</template>

<style lang="scss">
.price--container {
  display: flex;
  align-items: center;
  gap: 0.6rem;

  &.with_padding {
    @media (max-width: map-get($grid-breakpoints, md)) {
      padding: 1rem 0;
    }
  }

  &.return_line {
    flex-direction: column;
  }

  .price {
    display: flex;

    .price-value {
      font-weight: 500;
      color: var(--color-navy-600);
      font-size: 2rem;
      font-family: var(--font-secondary);
    }

    .text {
      display: inline-block;
      margin-left: 0.2rem;
    }
  }
}
</style>
